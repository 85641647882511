import React from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useStateValue } from "../../StateProvider";

import Logo from "../../Assets/icons/logo.svg";
import Home from "../../Assets/icons/home.svg";
import About from "../../Assets/icons/attention-circle.svg";
import Products from "../../Assets/icons/product.svg";
import Contact from "../../Assets/icons/contact.svg";
import Order from "../../Assets/icons/orders.svg";
import Dashboard from "../../Assets/icons/dashboard.svg";
import { ReactComponent as Message } from "../../Assets/icons/message.svg";
import Edit from "../../Assets/icons/edit.svg";
import Report from "../../Assets/icons/flag.svg";
import LogoutIcon from "../../Assets/icons/log-out.svg";
import { MdOutlineBusiness } from "react-icons/md";
import { LuClipboardList } from "react-icons/lu";
import "bootstrap/dist/css/bootstrap.min.css";
import "./menuOffCanvas.scss";
import { Link } from "react-router-dom";
import { MdOutlineFeedback } from "react-icons/md";
function MenuOffCanvas() {
  const [{ showNavCanvas, isLoggedIn }, dispatch] = useStateValue();

  const handleOffCanvas = (status) => {
    dispatch({
      type: "SET_NAV_CANVAS_SHOW",
      status: status,
    });
  };

  const openLoginForm = (status) => {
    handleOffCanvas(false);
    dispatch({
      type: "SET_LOGIN_MODAL_SHOW",
      status: status,
    });
  };
  const handleLogOut = () => {
    handleOffCanvas(false);
    dispatch({
      type: "SET_USER_LOGGED_IN",
      status: false,
    });
    localStorage.clear();
    sessionStorage.clear();
  };

  return (
    <Offcanvas
      show={showNavCanvas}
      placement="end"
      onHide={() => handleOffCanvas(false)}
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>
          <Link to="/home">
            <img src={Logo} alt="" />
          </Link>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Link
          className="item text-decoration-none"
          to="/home"
          onClick={() => {
            handleOffCanvas(false);
          }}
        >
          <img src={Home} alt="" srcset="" />
          <p>Home</p>
        </Link>
        <Link
          className="item"
          to="/about-us"
          onClick={() => {
            sessionStorage.setItem("scroll_about", 0);
            handleOffCanvas(false);
          }}
        >
          <img src={About} alt="" srcset="" />
          <p>About Us</p>
        </Link>
        <Link
          className="item"
          to="/products-and-service"
          onClick={() => {
            handleOffCanvas(false);
          }}
        >
          <img src={Products} alt="" srcset="" />
          <p>Products</p>
        </Link>
        <Link
          className="item"
          to="/blogs"
          onClick={() => {
            handleOffCanvas(false);
          }}
        >
            <LuClipboardList size={24} />
          {/* <img src={Products} alt="" srcset="" /> */}
          <p>Blogs</p>
        </Link>
        <Link
          className="item"
          to="/contact-us"
          onClick={() => {
            handleOffCanvas(false);
          }}
        >
          <img src={Contact} alt="" srcset="" />
          <p>Contact Us</p>
        </Link>
      
        {/* <Link
          className="item"
          to="/feedback"
          onClick={() => {
            handleOffCanvas(false);
          }}
        >
          <MdOutlineFeedback size={24} />
          <p>Feedback</p>
        </Link> */}
        <Link
          className="item"
          to="/usa-business-registration"
          onClick={() => {
            handleOffCanvas(false);
          }}
        >
          <MdOutlineBusiness size={24} />
          <p> USA Business Registration </p>
        </Link>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default MenuOffCanvas;
