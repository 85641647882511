import React, { useState } from "react";
import ProductTable from "./ProductTable";

import { ReactComponent as ArrowBlack } from "../../../Assets/icons/black-arrow.svg";
import "./products.scss";
import { Link, useParams } from "react-router-dom";
import { GetProductsByCategory } from "../../../apiCall";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { CACHE_TIME } from "../../../Constants";
import { Helmet } from "react-helmet";

const Products = () => {
  const [products, setProducts] = useState();
  const [categoryDetails, setCategoryDetails] = useState();
  const { title } = useParams();
 

  useQuery(
    ["products-by-category", title],
    () => GetProductsByCategory(title),
    {
      enabled: title ? true : false,
      // staleTime: STALE_TIME,
      cacheTime: CACHE_TIME,
      onSuccess: (data) => {
        // console.log(data);
        if (data.data.status[0].Error === "False") {
          setProducts(data.data.value.products);
          setCategoryDetails(data.data.value.category_details);
        }
      },
      onError: (err) => {
        // console.log(err, "ERROR");
        if (err.message) {
          toast.error(err.message);
        } else {
          toast.error("Something went wrong");
        }
      },
    }
  );
  return (
    <div className="products-main">
     
      <Link to="/products-and-service" className="arrow primary-btn">
        <ArrowBlack />
      </Link>
      <h1>{categoryDetails?.title}</h1>
      <p>{categoryDetails?.description}</p>
      <ProductTable products={products} type={"products"} />
    </div>
  );
};

export default Products;
