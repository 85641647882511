import React from "react";
import "./BusinessExpansionUSA.scss";
import bgImage from "../../Assets/images/usa-expantion-img-1.webp";
import image1 from "../../Assets/images/usa-expantion-img-2.png";
import image2 from "../../Assets/images/usa-expantion-img-3.png";
import verifiedLogo from "../../Assets/images/verified-logo.png";
import { ReactComponent as Call } from "../../Assets/icons/call.svg";
import { ReactComponent as Email } from "../../Assets/icons/mail.svg";
import { ReactComponent as Instagram } from "../../Assets/icons/instagram.svg";
import { ReactComponent as Location } from "../../Assets/icons/location.svg";
import { ReactComponent as Facebook } from "../../Assets/icons/facebook.svg";
import { ReactComponent as LinkedIn } from "../../Assets/icons/linkedIn.svg";
import { ReactComponent as Twitter } from "../../Assets/icons/twitter.svg";
import { ReactComponent as Process } from "../../Assets/icons/process.svg";
import { ReactComponent as ProcessMobile } from "../../Assets/icons/process-mobile.svg";
import CountryCodes from "../../CountryCodes.json";

import { FAQ, FAQUSA } from "../../utils/data";
import { Accordion } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { SubmitContactRequest } from "../../apiCall";
const BusinessExpansionUSA = () => {
  const navigateTo = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const submitForm = async (data) => {
    data.name = data.first_name + " " + data.last_name;
    const Re = await SubmitContactRequest(data);
    // console.log(Re);

    if (Re.data?.status[0].Error === "False") {
      toast.success(
        "We have received your query and will get back to you soon."
      );
      reset();
      navigateTo("/thank-you");

    } else {
      if (Re.data?.status) {
        toast.error(
          Re.data.status[0].Message
            ? Re.data.status[0].Message
            : Re.data.status[0].ResponseMessage
        );
      } else {
        toast.error("Something went wrong, Please try again.");
      }
    }
  };
  return (
    <div className="busisness-expansion-usa">
    
      {/****************************************** Section 1 ******************************************/}
      <section className=" section section-1 banner">
        <div className="bg-image">
          <img src={bgImage} />
        </div>
        <div className="verified-logo">
          <img src={verifiedLogo} />
        </div>
        <div className="content">
          <p>With Expert Guidance from Aapka CFO</p>
          <h2>Take Your Business to the USA </h2>
          <p>
            Simplify your journey to U.S. market success with our end-to-end
            support
          </p>
          <div className="call-btn-wrapper">
            <Link className="call-btn" to="/schedule-call">
              <button className="primary-btn">
                Schedule Your Free Consultation
              </button>
            </Link>
            {/* <span className="my-svg">
                             <img src={SVG} alt="" />
                             <img className="animate-SVG" src={AnimateSVG} alt="" />
                           </span> */}
          </div>
        </div>
      </section>
      {/****************************************** Section 2 ******************************************/}
      <section className="section section-2">
        <div className="row-1">
          <div className="left">
            <img src={image2} />
          </div>
          <div className="right">
            <div className="header">
              <h2>Unlock Growth with</h2>
              <h1>
                Seamless USA
                <span> Business Registration</span>
              </h1>
            </div>
            <p>
              The United States, the world’s largest economy, offers
              unparalleled opportunities for business growth and innovation.
              With access to over 330 million consumers and a thriving ecosystem
              for startups, the U.S. is a global hub for entrepreneurs and
              businesses seeking to expand. From cutting-edge technology to
              diverse industries, the U.S. market provides a dynamic platform to
              scale your business, connect with a global audience, and gain a
              competitive edge in your sector. By entering this market through a
              seamless USA business registration process, you unlock endless
              possibilities for revenue generation and brand recognition—making
              Aapka CFO the ideal partner to simplify your registration and
              ensure a successful expansion.
            </p>
          </div>
        </div>
      </section>
      {/****************************************** Section 3 ******************************************/}
      <section className="section section-3">
        <div className="row-1">
          <div className="left">
            <div className="header">
              <h2>Why Aapka CFO is Your Perfect </h2>
              <h1>
                <span>Partner For </span>
                USA Business Registration
              </h1>
            </div>
            <p>
              <ul>
                <li>
                  End-to-End
                  <span> Application Assistance</span>
                </li>
                <li>
                  Business Expansion
                  <span> Paperwork & Delegate Access</span>
                </li>
                <li>
                  Business
                  <span> Pitch Preparation</span>
                </li>
                <li>
                  Free 10-Year
                  <span> B1/B2 Visa</span>
                </li>
                <li>
                  Interview
                  <span> Guidance</span>
                </li>
                <li>
                  Startup
                  <span> Ecosystem </span>
                  Networking
                </li>
              </ul>
            </p>
          </div>
          <div className="right">
            <img src={image1} />
          </div>
        </div>
      </section>
      {/****************************************** Section 4 ******************************************/}
      <section className="section section-4">
        <h1>
          Our 5-Step Process for
          <span>
            {" "}
            USA Business Registration and
            <span className="text-red"> Market Expansion</span>
          </span>
        </h1>
        <div className="image-wrapper-web">
          <Process />
        </div>
        <div className="image-wrapper-mobile">
          <ProcessMobile />
        </div>
        <div className="call-btn-wrapper">
          <Link to="/schedule-call" className="call-btn">
            <button className="primary-btn">Start Your U.S. Journey Now</button>
          </Link>
        </div>
        {/* <div className="steps-mobile">
          <ul style={{ listStyleType: "decimal" }}>
            <li className="step">
              Business Pitch & Visa Support
              <br />
              <span>
                Stand out with strategic pitch guidance & secure a free 10-year
                B1/B2 visa.
              </span>
            </li>
            <li className="step">
              Preparation for U.S. Expansion
              <br />
              <span>We handle your paperwork and delegate access.</span>
            </li>
            <li className="step">
              U.S. Interview Prep
              <br />
              <span>
                Be well-prepared to confidently enter the U.S. market.
              </span>
            </li>
            <li className="step">
              Application & Form Assistance
              <br />
              <span>We manage all your documentation needs.</span>
            </li>
            <li className="step">
              Connect with the U.S. Startup Ecosystem
              <br />
              <span>Grow your network and business opportunities.</span>
            </li>
          </ul>
        </div> */}
        <p>
          <ul style={{ listStyleType: "decimal" }}>
            <li>
              <span>Application & Form Assistance </span>
              Aapka CFO provides comprehensive support in managing all necessary
              documentation for USA Business Registration, ensuring accurate and
              timely submissions to streamline the initial setup and focus on
              strategic goals.
            </li>
            <li>
              <span>Preparation for U.S. Expansion </span>
              Following documentation, Aapka CFO assists in the strategic setup
              of business operations in the U.S., ensuring compliance with laws
              and establishing necessary operational frameworks for seamless
              market entry.
            </li>
            <li>
              <span>Business Pitch & Visa Support </span>
              The firm helps craft compelling business pitches and secures B1/B2
              Visas for business travel to the U.S., providing strategic
              guidance to stand out in the competitive market and facilitate
              operational setup on U.S. soil.
            </li>
            <li>
              <span>U.S. Interview Prep </span>
              Aapka CFO prepares business owners for interviews and meetings,
              focusing on effective communication of business values to U.S.
              partners and investors, enhancing the chances of successful market
              penetration.
            </li>
            <li>
              <span>Connect with the U.S. Startup Ecosystem </span>
              The final step involves integrating businesses into the U.S.
              startup ecosystem, offering networking opportunities and strategic
              introductions to key stakeholders to establish and grow business
              networks in the U.S.
            </li>
          </ul>
        </p>
      </section>
      {/****************************************** Section 7 ******************************************/}
      <section className="section faq-section">
        <h2 className="faq-heading">
          Frequently Asked <span className="highlight">Questions</span>
        </h2>
        <Accordion>
          {FAQUSA.map((item, index) => (
            <Accordion.Item eventKey={index} key={index}>
              <Accordion.Header>{item.question}</Accordion.Header>
              <Accordion.Body>{item.answer}</Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </section>

      {/****************************************** Section 8 ******************************************/}

      <section className="section-7">
        <div className="section-container section-7-container">
          <div className="connect-form">
            <h5 className="section-heading">Connect With Us</h5>
            <div className="contact-detail">
              <div className="left">
                <form>
                  <div className="input-grp">
                    <div className="input">
                      <label>First Name</label>
                      <input
                        type="text"
                        name="first_name"
                        placeholder="First Name"
                        {...register("first_name", {
                          required: true,
                          maxLength: 35,
                        })}
                      />
                      {errors.first_name?.type === "required" && (
                        <p className="form-error">First name is required.</p>
                      )}
                      {errors.first_name?.type === "maxLength" && (
                        <p className="form-error">
                          Length of first name can not be more than 35.
                        </p>
                      )}
                    </div>
                    <div className="input">
                      <label>Last Name</label>

                      <input
                        type="text"
                        name="last_name"
                        placeholder="Last Name"
                        {...register("last_name", {
                          required: true,
                          maxLength: 35,
                        })}
                      />
                      {errors.last_name?.type === "required" && (
                        <p className="form-error">Last name is required.</p>
                      )}
                      {errors.last_name?.type === "maxLength" && (
                        <p className="form-error">
                          Length of last name can not be more than 35.
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="input-grp">
                    <div className="input">
                      <label>Email</label>
                      <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        {...register("email", {
                          required: true,
                        })}
                      />
                      {errors.email?.type === "required" && (
                        <p className="form-error">Email is required.</p>
                      )}
                    </div>
                    <div className="call-grp">
                      <label>Contact No.</label>

                      <div className="input">
                        <select
                          name=""
                          id=""
                          defaultValue={"+91"}
                          {...register("country_code")}
                        >
                          {CountryCodes.sort().map((code) => (
                            <option
                              value={code.dial_code}
                            >{`${code.code} (${code.dial_code})`}</option>
                          ))}
                          {/* 
                                            <option value='+91'>+91</option>
                                            <option value='+92'>+92</option>
                                            <option value='+93'>+93</option>
                                            <option value='+94'>+94</option>
                                            <option value='+95'>+95</option> */}
                        </select>
                        <input
                          type="number"
                          name="phone_number"
                          placeholder="Phone Number"
                          {...register("phone_number", {
                            required: true,
                          })}
                        />
                      </div>
                      {errors.phone_number?.type === "required" && (
                        <p className="form-error">Phone number is required.</p>
                      )}
                    </div>
                  </div>
                  <div className="input-grp">
                    <div className="input">
                      <label> Message</label>

                      <textarea
                        type="text"
                        placeholder="Message"
                        rows={3}
                        {...register("message", {
                          required: true,
                        })}
                      />
                      {errors.message?.type === "required" && (
                        <p className="form-error">
                          Message field can not be empty.
                        </p>
                      )}
                    </div>
                  </div>
                  <button onClick={handleSubmit(submitForm)}>Submit</button>
                </form>
              </div>
              <div className="right">
                <div className="item-wrapper">
                  <div className="item address-1">
                    <div className="icon">
                      {" "}
                      <Location width={20} height={20} />{" "}
                    </div>
                    <p>
                      112C, 138C Building No. 280, Taweelah, Abu Dhabi, United
                      Arab Emirates
                    </p>
                  </div>
                  <div className="item address-2">
                    <div className="icon">
                      {" "}
                      <Location width={20} height={20} />
                    </div>
                    <p>
                      501 Solitaire 1, Opp Infinity Mall, Link Road Malad (West)
                      Mumbai - 400064
                    </p>
                  </div>
                  <div className="item phone-number">
                    <div className="icon">
                      <Call width={20} height={20} />{" "}
                    </div>
                    <p>+91 93218 22974</p>
                  </div>
                  <div className="item email">
                    <div className="icon">
                      {" "}
                      <Email width={20} height={20} />{" "}
                    </div>
                    <p>info@aapkacfo.com</p>
                  </div>
                  <span className="item social">
                    <div className="logos">
                      {/* <span className='insta'>{<Insta />}</span> */}
                      <a
                        href="https://www.instagram.com/aapkacfo/"
                        className="instagram"
                        target="__blank"
                      >
                        {<Instagram width={16} height={16} />}
                      </a>
                      <a
                        href="https://www.facebook.com/profile.php?id=100062852317845"
                        className="facebook"
                        target="__blank"
                      >
                        {<Facebook width={16} height={16} />}
                      </a>
                      <a
                        href="https://www.linkedin.com/company/aapka-cfo/"
                        className="linkedin"
                        target="__blank"
                      >
                        {<LinkedIn width={16} height={16} />}
                      </a>
                      <a
                        href="https://twitter.com/AapkaCfo"
                        className="twitter"
                        target="__blank"
                      >
                        {<Twitter width={16} height={16} />}
                      </a>
                      {/* <a className='whatsapp'>{<WhatsApp />}</a> */}
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default BusinessExpansionUSA;
