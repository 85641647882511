import React, { useEffect, useRef, useState } from "react";
import AnimateSVG from "../../Assets/home/section1-animate.svg";
import SVG from "../../Assets/home/section1.svg";
// import { ReactComponent as Play } from "../../Assets/icons/play.svg";
import { ReactComponent as Call } from "../../Assets/icons/call.svg";
import { ReactComponent as Milestones } from "../../Assets/icons/milestones.svg";
import { ReactComponent as Affordable } from "../../Assets/icons/affordable.svg";
import { ReactComponent as TailoredSolutions } from "../../Assets/icons/tailored-solutions.svg";
import { ReactComponent as ExperiencedCFOs } from "../../Assets/icons/experienced-CFOs.svg";
import { ReactComponent as Email } from "../../Assets/icons/mail.svg";
import { ReactComponent as Location } from "../../Assets/icons/location.svg";
import { ReactComponent as Facebook } from "../../Assets/icons/facebook.svg";
import { ReactComponent as LinkedIn } from "../../Assets/icons/linkedIn.svg";
import { ReactComponent as Twitter } from "../../Assets/icons/twitter.svg";
import { ReactComponent as Quote } from "../../Assets/icons/quote.svg";
import { ReactComponent as Platforms } from "../../Assets/icons/Platforms.svg";
// import { ReactComponent as WhatsApp } from "../../Assets/icons/whatsapp.svg";
import { ReactComponent as Instagram } from "../../Assets/icons/instagram.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay } from "swiper/modules";
// import LogoBg from "../../Assets/home/logo-bg.svg"
import Arrow from "../../Assets/icons/red-arrow.svg";
import AboutHero from "../../Assets/home/about-hero.svg";
// import ProductImg from "../../Assets/images/product.png";
// import CircleArrow from "../../Assets/icons/circle-arrow.svg";
import DownArrow from "../../Assets/icons/downArror.svg";
import Farmer from "../../Assets/home/farmer.svg";
import ContactImg from "../../Assets/home/contact.png";
import OurTeam from "../../Assets/home/team_image.jpeg";
// import OurTeam from "../../Assets/home/our-team.png";
import FounderPic from "../../Assets/home/founder_pic.jpg";
import Platform1 from "../../Assets/images/platform1.png";
import Platform2 from "../../Assets/images/platform2.png";
import Platform3 from "../../Assets/images/platform3.png";
import Platform4 from "../../Assets/images/platform4.webp";
import Platform5 from "../../Assets/images/platform5.png";
import { ReactComponent as ArrowBlack } from "../../Assets/icons/black-arrow.svg";
import { useIsInViewport } from "../../utils/utils";
// import Triangle from "../../Assets/icons/triangle.svg"
import { Accordion } from "react-bootstrap";
// import ClientImg from "../../Assets/home/client-img.svg";
import "./home.scss";
import { useForm } from "react-hook-form";
import {
  GetAllProducts,
  GetCategoryList,
  GetClientDetails,
  GetHomeContent,
  SubmitContactRequest,
} from "../../apiCall";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { CACHE_TIME, domainName } from "../../Constants";
import CountUp from "react-countup";
import { useStateValue } from "../../StateProvider";
import CountryCodes from "../../CountryCodes.json";
import Footer from "../../Components/Footer/Footer";
import Slider from "react-slick";
import { FAQ } from "../../utils/data";
import { Helmet } from "react-helmet";
import FAQs from "./components/FAQs";

// let slidesPerView = 5;
// if (window.innerWidth <= 550) {
//     slidesPerView = 4;
// }
// if (window.innerWidth <= 450) {
//     slidesPerView = 3.5;
// }
// if (window.innerWidth <= 350) {
//     slidesPerView = 3;

// }

let slidesPerView = 9;
if (window.innerWidth <= 350) {
  slidesPerView = 2;
} else if (window.innerWidth <= 450) {
  slidesPerView = 2;
} else if (window.innerWidth <= 550) {
  slidesPerView = 2;
} else if (window.innerWidth <= 650) {
  slidesPerView = 5;
} else if (window.innerWidth <= 750) {
  slidesPerView = 5.5;
} else if (window.innerWidth <= 850) {
  slidesPerView = 6;
} else if (window.innerWidth <= 950) {
  slidesPerView = 7;
} else if (window.innerWidth <= 1050) {
  slidesPerView = 8;
}

// const ProductCategories = [
//     "Registration and certification",
//     "Registration and certification",
//     "Registration and certification",
//     "Registration and certification",
//     "Registration and certification",
// ];
// const Products = [
//     {
//         title: "Registration and certification",
//         about: "Free strategy session",
//         img: ProductImg,
//     },
//     {
//         title: "Registration and certification",
//         about: "Free strategy session",
//         img: ProductImg,
//     },
// ];
// const clientReview = [
//     {
//         img: ClientImg,
//         name: "Shailesh",
//         review: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit",
//         company: "shaileshtech company",
//     },
//     {
//         img: ClientImg,
//         name: "Shailesh",
//         review: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit",
//         company: "shaileshtech company",
//     },
//     {
//         img: ClientImg,
//         name: "Shailesh",
//         review: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit",
//         company: "shaileshtech company",
//     },
// ];
const Home = () => {
  const [{ isLoggedIn }, dispatch] = useStateValue();
  const [isProductCatOpen, setIsProductCatOpen] = useState(false);
  const [selectedProductCategory, setSelectedProductCategory] = useState(-1);
  const [homeContent, setHomeContent] = useState();
  const [clientDetails, setClientDetails] = useState();
  const [activeKey, setActiveKey] = useState(null);
  
  const navigateTo = useNavigate();
  const toggleAccordion = (key) => {
    setActiveKey(activeKey === key ? null : key); // Toggle the active key
  };
  const containerRef = useRef();
  const scrollLeft = () => {
    containerRef.current.scrollLeft = Math.max(
      0,
      containerRef.current.scrollLeft - 350
    );
  };
  const scrollRight = () => {
    containerRef.current.scrollLeft = Math.min(
      containerRef.current.scrollWidth,
      containerRef.current.scrollLeft + 350
    );
  };
  const clientReviewRef = useRef();
  let autoScrollInterval = null; // To store the interval ID
  // Scroll to the next slide
  const scrollRightReview = () => {
    const { scrollWidth, scrollLeft, offsetWidth } = clientReviewRef.current;
    const maxScrollLeft = scrollWidth - offsetWidth;
    console.log(scrollLeft, maxScrollLeft);

    if (Math.round(scrollLeft) >= maxScrollLeft) {
      // If at the end, go back to the beginning
      clientReviewRef.current.scrollLeft = 0;
    } else {
      clientReviewRef.current.scrollLeft += offsetWidth + 10;
    }
  };

  // Scroll to the previous slide
  const scrollLeftReview = () => {
    const { scrollLeft, offsetWidth } = clientReviewRef.current;

    if (scrollLeft === 0) {
      // If at the beginning, go to the last slide
      clientReviewRef.current.scrollLeft =
        clientReviewRef.current.scrollWidth - offsetWidth;
    } else {
      clientReviewRef.current.scrollLeft -= offsetWidth + 10;
    }
  };

  // Auto-scroll function with infinite loop
  const startAutoScroll = (direction = "right", interval = 3000) => {
    clearAutoScroll(); // Clear any existing interval
    autoScrollInterval = setInterval(() => {
      if (direction === "right") {
        scrollRightReview();
      } else {
        scrollLeftReview();
      }
    }, interval);
  };

  // Stop auto-scroll
  const clearAutoScroll = () => {
    if (autoScrollInterval) {
      clearInterval(autoScrollInterval);
      autoScrollInterval = null;
    }
  };

  // Usage in effect (if needed for auto-start)
  useEffect(() => {
    startAutoScroll("right"); // Auto-scroll to the right on component mount

    // Clean up on unmount
    return () => {
      clearAutoScroll();
    };
  }, []);

  const section2Ref = useRef();
  const section3Ref = useRef();
  const section4Ref = useRef();
  const section5Ref = useRef();
  const section6Ref = useRef();
  const section7Ref = useRef();
  const section8Ref = useRef();
  const isSection2InViewport = useIsInViewport(section2Ref);
  const isSection3InViewport = useIsInViewport(section3Ref);
  const isSection4InViewport = useIsInViewport(section4Ref);
  const isSection5InViewport = useIsInViewport(section5Ref);
  const isSection6InViewport = useIsInViewport(section6Ref);
  const isSection7InViewport = useIsInViewport(section7Ref);
  const isSection8InViewport = useIsInViewport(section8Ref);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const submitForm = async (data) => {
    try {
      data.name = data.first_name + " " + data.last_name;
      const Re = await SubmitContactRequest(data);
      // console.log(Re);

      if (Re.data?.status[0].Error === "False") {
        toast.success(
          "We have received your query and will get back to you soon."
        );
        reset();
        navigateTo("/thank-you");
      } else {
        if (Re.data?.status) {
          toast.error(
            Re.data.status[0].Message
              ? Re.data.status[0].Message
              : Re.data.status[0].ResponseMessage
          );
        } else {
          toast.error("Something went wrong, Please try again.");
        }
      }
    } catch {}
  };

  const showLoginModel = () => {
    dispatch({
      type: "SET_LOGIN_MODAL_SHOW",
      status: true,
    });
  };

  useEffect(() => {
    if (isSection2InViewport) {
      section2Ref.current.classList.add("animate");
    }
    if (isSection3InViewport) {
      section3Ref.current.classList.add("animate");
    }
    if (isSection4InViewport && section4Ref.current) {
      section4Ref.current.classList.add("animate");
    }
    if (isSection5InViewport) {
      section5Ref.current.classList.add("animate");
    }
    if (isSection6InViewport) {
      section6Ref.current.classList.add("animate");
    }
    if (isSection7InViewport) {
      section7Ref.current.classList.add("animate");
    }
    if (isSection8InViewport) {
      section8Ref.current.classList.add("animate");
    }
  }, [
    isSection2InViewport,
    isSection3InViewport,
    isSection4InViewport,
    isSection5InViewport,
    isSection6InViewport,
    isSection7InViewport,
    isSection8InViewport,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const { isLoading: home_content_loading } = useQuery(
    ["get-home-content"],
    () => GetHomeContent(),
    {
      // staleTime: STALE_TIME,
      cacheTime: CACHE_TIME,
      onSuccess: (data) => {
        if (data?.data?.status[0].Error === "False") {
          setHomeContent(data.data.value);
        }
      },
      onError: (err) => {
        if (err.message) {
          toast.error(err.message);
        } else {
          toast.error("Something went wrong");
        }
      },
    }
  );

  const { isLoading: client_loading } = useQuery(
    ["get-client"],
    () => GetClientDetails(),
    {
      // staleTime: STALE_TIME,
      cacheTime: CACHE_TIME,
      onSuccess: (data) => {
        if (data?.data?.status[0].Error === "False") {
          setClientDetails(data.data.value);
        }
      },
      onError: (err) => {
        if (err.message) {
          toast.error(err.message);
        } else {
          toast.error("Something went wrong");
        }
      },
    }
  );
  const { isLoading: categories_loading } = useQuery(
    ["get-categories"],
    () => GetCategoryList(),
    {
      // staleTime: STALE_TIME,
      cacheTime: CACHE_TIME,
      onSuccess: (data) => {
        if (data.data?.status[0].Error === "False") {
          setCategories(data.data.value);

          const min_id = data?.data?.value?.reduce(
            (min_id, cat) => Math.min(min_id, cat.id),
            Number.MAX_SAFE_INTEGER
          );
          setSelectedProductCategory(min_id);
        }
      },
      onError: (err) => {
        if (err.message) {
          toast.error(err.message);
        } else {
          toast.error("Something went wrong");
        }
      },
    }
  );
  const { isLoading: all_products_loading } = useQuery(
    ["all-products"],
    () => GetAllProducts(),
    {
      // staleTime: STALE_TIME,
      cacheTime: CACHE_TIME,
      onSuccess: (data) => {
        if (data.data?.status[0].Error === "False") {
          setProducts(data.data.value);
        }
      },
      onError: (err) => {
        if (err.message) {
          toast.error(err.message);
        } else {
          toast.error("Something went wrong");
        }
      },
    }
  );
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      <div className="home-main">
        
        {/****************************************** Section 1 ******************************************/}
        <section className="section section-1">
          <div className="section-container section-1-container">
            {home_content_loading ? (
              <div className="component-loading main-text-loading-cmp"></div>
            ) : (
              <>
                <div className="main-text">
                  <span className="line-1">
                    Empowering Businesses with
                    {/* <br /> into {" "}
              <span className="bold">success</span>{" "} */}
                  </span>
                  <h2 className="bold line-2">
                    Expert <span>CFO Services</span>
                  </h2>
                  <p className="slogan">
                    Strategic Financial Expertise to Drive Your Business Growth
                  </p>
                  <p>
                    {homeContent?.home_description
                      .split("\n")
                      .map((line, index) => (
                        <React.Fragment key={index}>
                          {line}
                          <br />
                        </React.Fragment>
                      ))}
                    {/* {homeContent?.home_description} */}
                  </p>
                  {/* Our team with our clients helps them in achieving sustainable and inclusive growth */}
                  <div>
                    <Link to="/schedule-call" className="call-btn">
                      <button className="primary-btn">
                        Schedule Your Free Consultation
                      </button>
                      <span className="call-icon">
                        <Call />
                      </span>
                    </Link>
                    {/* <span className="my-svg">
                    <img src={SVG} alt="" />
                    <img className="animate-SVG" src={AnimateSVG} alt="" />
                  </span> */}
                  </div>
                  {/* <span className='gif'>
                            <iframe
                                className='HTML-Embed'
                                width='264'
                                height='50'
                                src='https://www.youtube.com/embed/UiiRSCpKHHQ?autoplay=1&mute=1&loop=1'
                                title='YouTube video player'
                                frameBorder='0'
                                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                                allowFullScreen></iframe>
                            <a
                                href='https://www.youtube.com/watch?v=UiiRSCpKHHQ'
                                target='__blank'
                                className='play'>
                                <Play />
                            </a>
                        </span> */}
                </div>
                <span className="my-svg">
                  <img src={SVG} alt="" />
                  <img className="animate-SVG" src={AnimateSVG} alt="" />
                </span>
              </>
            )}

            <div className="statistics">
              {home_content_loading ? (
                <div className="loading-statistics-container">
                  <div className="component-loading statistics-loading-cmp"></div>
                  <div className="component-loading statistics-loading-cmp"></div>
                  <div className="component-loading statistics-loading-cmp"></div>
                </div>
              ) : (
                homeContent?.statistics.map((stat, indx) => (
                  <div key={"stat" + indx}>
                    <span className="number">
                      <CountUp
                        start={Math.max(stat.number - 100, 0)}
                        end={stat.number}
                        duration={2}
                        useEasing={false}
                        // delay={1}
                      />
                      +
                    </span>
                    <span className="text">{stat.title}</span>
                  </div>
                ))
              )}
            </div>
          </div>
        </section>
        {/****************************************** Section 2 ******************************************/}

        <section className="section section-4">
          <div className="section-4-container section-container">
            {home_content_loading ? (
              <div className="component-loading loading-about-us-img"></div>
            ) : (
              <img src={AboutHero} alt="" />
            )}
            <div className="content">
              {home_content_loading ? (
                <div className="component-loading loading-section-heading"></div>
              ) : (
                <h1 className="section-heading">
                  {/* <span className='vertical-text'>
                                    Who we are
                                </span> */}
                  About <br />{" "}
                  <span className="bold" ref={section4Ref}>
                    Aapka <span style={{ color: "#e31e26" }}>CFO</span>
                  </span>
                </h1>
              )}
              {home_content_loading ? (
                <div className="component-loading loading-about-us-content"></div>
              ) : (
                <>
                  <p className="text">
                    {homeContent?.about_aapkacfo
                      .split("\n")
                      .map((line, index) => (
                        <React.Fragment key={index}>
                          {line}
                          <br />
                        </React.Fragment>
                      ))}
                  </p>
                  {/* <Link to="/schedule-call" className='call-btn'>
                                     <button
                                        className='primary-btn'
                                     
                                                >
                                        Schedule call with us
                                    </button>
                                    <span className='call-icon'>
                                        <Call />
                                    </span>
                                </Link> */}
                </>
              )}
            </div>
          </div>
        </section>

        {/****************************************** Section 3 ******************************************/}

        <section className="section section-milestones">
          <div className="left">
            {home_content_loading ? (
              <div className="component-loading loading-section-heading"></div>
            ) : (
              <>
                <h2>Milestones:</h2>
                <ul className="list">
                  <li>
                    {" "}
                    <span>2020:</span> Founded with a mission to empower
                    businesses with expert CFO services, serving 200 clients in
                    our first year.
                  </li>
                  <li>
                    {" "}
                    <span>2021:</span> Recognized as one of Mumbai's Top 10
                    Fastest Growing Fintech Companies, growing to 650 clients.
                  </li>
                  <li>
                    {" "}
                    <span>2022:</span> Expanded to the UAE, featured on the
                    front page of Khaleej Times and ET Entrepreneur, and proudly
                    served 1,200 clients.
                  </li>
                  <li>
                    {" "}
                    <span>2023:</span> Entered the USA market, achieving a
                    client base of 3,600 businesses globally.
                  </li>
                  <li>
                    {" "}
                    <span>2024:</span> Led a delegation to support Indian
                    companies in expanding to the USA and reached an impressive
                    milestone of 7,800 clients.
                  </li>
                </ul>
                <p>
                  Our journey reflects our commitment to delivering excellence
                  and innovation in CFO services worldwide.
                </p>
              </>
            )}
          </div>
          <div className="right">
            {home_content_loading ? (
              <div className="component-loading loading-about-us-img"></div>
            ) : (
              <Milestones className="milestones-svg" />
            )}
          </div>
        </section>
        {/****************************************** Section 4 ******************************************/}
        <div className="section-wrapper">
          {home_content_loading ? (
            <div className="component-loading loading-section-heading"></div>
          ) : (
            <section className="section section-our-team">
              <></>
              <div className="heading-container">
                <div className="heading">Our Team</div>
              </div>
              <img src={OurTeam} alt="" />
              <div className="team-detail-container">
                <div className="triangel"></div>
                <div className="team-detail">
                  <p>
                    At Aapka CFO, our team specializes in delivering exceptional
                    CFO services tailored to your business needs. Comprising
                    experienced financial strategists and dedicated
                    professionals, we provide personalized solutions to drive
                    your company’s growth. From financial planning to
                    decision-making support, our collaborative approach ensures
                    efficiency, transparency, and measurable success. Partner
                    with us for expert CFO services that elevate your business.
                  </p>
                </div>
              </div>
            </section>
          )}
        </div>
        {/****************************************** Section 5 ******************************************/}
        {clientDetails && (
          <div className="section-2 ">
            <div className="section-2-container section-container">
              {client_loading ? (
                <div className="component-loading loading-section-heading"></div>
              ) : (
                <div className="section-heading">
                  Recognized By
                  <span>
                    {" "}
                    Leading <span className="text-red">Platforms </span>
                  </span>
                  {/* <span className='vertical-text'>
                                    Trusted By
                                </span>
                                <p>Clients & Companies</p>{" "}
                                <p className='bold' ref={section2Ref}>
                                    We Work With
                                </p>{" "} */}
                </div>
              )}
              <div className="client-container">
                <div className="client-logo-swiper">
                  {client_loading ? (
                    <div className="loading-client-logo-container">
                      <div className="component-loading loading-client"></div>
                      <div className="component-loading loading-client"></div>
                      <div className="component-loading loading-client"></div>
                      <div className="component-loading loading-client"></div>
                      <div className="component-loading loading-client"></div>
                      <div className="component-loading loading-client"></div>
                      <div className="component-loading loading-client"></div>
                      <div className="component-loading loading-client"></div>
                      <div className="component-loading loading-client"></div>
                      <div className="component-loading loading-client"></div>
                    </div>
                  ) : (
                    <Swiper
                      spaceBetween={24}
                      centeredSlides={true}
                      autoplay={{
                        delay: 500,
                        disableOnInteraction: false,
                      }}
                      loopedSlides={true}
                      loop={true}
                      modules={[Autoplay]}
                      className="mySwiper"
                      slidesPerView={slidesPerView}
                      // slidesPerGroup={3}
                      speed={500}
                    >
                      <SwiperSlide key={"client" + 1}>
                        <div className="client">
                          <img src={Platform1} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide key={"client" + 2}>
                        <div className="client">
                          <img src={Platform2} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide key={"client" + 3}>
                        <div className="client">
                          <img src={Platform3} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide key={"client" + 4}>
                        <div className="client">
                          <img src={Platform4} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide key={"client" + 5}>
                        <div className="client">
                          <img src={Platform5} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide key={"client" + 6}>
                        <div className="client">
                          <Platforms />
                        </div>
                      </SwiperSlide>
                      {/* {clientDetails?.map((client) => (
                        <SwiperSlide key={"client" + client.id}>
                          <div className="client">
                            <img
                              src={
                                domainName + "/uploads/" + client.company_logo
                              }
                              alt=""
                            />
                          </div>
                        </SwiperSlide>
                      ))} */}
                    </Swiper>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        {/* ***************************************** Section 6 ***************************************** */}
        <section className="section-our-founder">
          <div className="heading">
            <h2>
              Our <br /> <span>Founder</span>{" "}
            </h2>
          </div>
          <div className="row-1">
            <div className="left">
              {home_content_loading ? (
                <div className="component-loading loading-section-heading"></div>
              ) : (
                <h5>A Visionary Leader and TEDx Speaker</h5>
              )}
              {home_content_loading ? (
                <div className="component-loading loading-content"></div>
              ) : (
                <div className="founder-detail">
                  <p className="p-1">
                    Ronak Soni is the co-founder and CEO of Aapka CFO, a fintech
                    platform empowering SMEs with trade finance solutions. With
                    over 16 years of experience and a master's in international
                    trade management, he is committed to making trade finance
                    accessible and reliable for businesses.
                  </p>
                  <p className="p-2">
                    Prior to Aapka CFO, Ronak co-founded IGE Global, India’s
                    first trade finance fintech platform, facilitating over $100
                    million in transactions annually. His expertise spans credit
                    card operations, marketing, leadership, and customer
                    service.
                  </p>
                  <p className="p-3">
                    Watch the TEDx Talk to learn more about his journey.
                  </p>
                  <div className="footer">
                    <a
                      href="https://www.youtube.com/watch?v=yzwEuLimZkw"
                      target="_blank"
                      className="call-btn"
                    >
                      <button className="primary-btn">
                        Watch the TEDx Talk
                      </button>
                    </a>
                    <div className="author">
                      <div className="name">Ronak Soni</div>
                      <div className="detail">Founder Director</div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="right">
              {home_content_loading ? (
                <div className="component-loading loading-img "></div>
              ) : (
                <iframe
                  width="420"
                  height="416"
                  src="https://www.youtube.com/embed/yzwEuLimZkw?si=FSuBAU-vRHxCFfUX"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              )}
            </div>
          </div>
          <div className="row-2">
            <div className="left">
              {/* <h5>A Visionary Leader and TEDx Speaker</h5> */}
              {home_content_loading ? (
                <div className="component-loading loading-section-heading"></div>
              ) : (
                ""
              )}
              {home_content_loading ? (
                <div className="component-loading loading-content"></div>
              ) : (
                <div className="founder-detail">
                  <p className="p-1">
                    Chirag Trivedi is the Managing Director of Aapka CFO and a
                    highly respected financial expert. Nominated for the 18th
                    Asian Business & Social Forum as one of the "AsiaOne Most
                    Influential Young Leaders 2021-22", Chirag has over 25 years
                    of experience in financial advisory and consulting.
                  </p>
                  <p className="p-2">
                    He began his career with Kotak Mahindra Bank and is the
                    owner and promoter of National Finance Corporation. With
                    significant experience across national and international
                    banks, private investors, private equity firms, and
                    structured trade, Chirag has developed deep expertise in
                    diverse financial products and services.
                  </p>
                  <div className="footer">
                    <div className="author">
                      <div className="name">Chirag Trivedi</div>
                      <div className="detail">Managing Director</div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="right">
              {home_content_loading ? (
                <div className="component-loading loading-img "></div>
              ) : (
                <img src={FounderPic} alt="" height="416" />
              )}
            </div>
          </div>
        </section>

        {/* ***************************************** Section 3 ***************************************** */}
        {/* {homeContent?.stratergy.length > 0 && (
                <section className='section-3 section'>
                    <div className='section-3-container section-container'>
                        {home_content_loading ? (
                            <div className='component-loading loading-section-heading'></div>
                        ) : (
                            <h1 className='section-heading'>
                                <span className='vertical-text'>Expertise</span>
                                <p>Our</p>{" "}
                                <p className='bold' ref={section3Ref}>
                                    Strategies
                                </p>{" "}
                            </h1>
                        )}
                        {home_content_loading ? (
                            <div className='loading-stratergies stratergies'>
                                <div className='component-loading loading-strat'></div>
                                <div className='component-loading loading-strat'></div>
                                <div className='component-loading loading-strat'></div>
                            </div>
                        ) : (
                            <div className='stratergies'>
                                {homeContent?.stratergy.map((strat, indx) => (
                                    <div
                                        className='stratergy'
                                        key={"strat" + indx}>
                                        <span className='title bold'>
                                            <p className='red'>{indx + 1}.</p>
                                            <p>{strat.title}</p>
                                        </span>
                                        <p className='content'>
                                            {strat.description}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </section>
            )}
            {!home_content_loading && <div className='logo-bg'></div>} */}

        {/****************************************** Section 7 ******************************************/}
        <section className="section section-5">
          <div className="section-container section-5-container">
            <div className="header">
              {categories_loading && all_products_loading ? (
                <div className="component-loading loading-section-heading"></div>
              ) : (
                <h1 className="section-heading">
                  <span className="vertical-text">Who we are</span>
                  <p>Our </p>{" "}
                  <p className="bold" ref={section5Ref}>
                    Products
                  </p>
                </h1>
              )}
              {!(categories_loading && all_products_loading) && (
                <div className="arrows">
                  <span className="arrow primary-btn" onClick={scrollLeft}>
                    <ArrowBlack />
                  </span>
                  <span
                    className="arrow primary-btn reverse"
                    onClick={scrollRight}
                  >
                    <ArrowBlack />
                  </span>
                </div>
              )}
            </div>
            {categories_loading && all_products_loading ? (
              <div className="loading-categories-drop-down component-loading"></div>
            ) : (
              <div
                className={`categories-drop-down ${
                  isProductCatOpen === true ? "open" : ""
                }`}
              >
                <div
                  className="selected"
                  onClick={() => setIsProductCatOpen((pre) => !pre)}
                >
                  <p>
                    {selectedProductCategory !== -1
                      ? categories.filter(
                          (cat) => cat.id === selectedProductCategory
                        )[0]?.title
                      : "All Categories"}
                  </p>
                  <img src={DownArrow} alt="" />
                </div>
                <div className="categories">
                  <div
                    onClick={() => {
                      setSelectedProductCategory(-1);
                      setIsProductCatOpen(false);
                    }}
                    className={`category`}
                  >
                    All Categories
                  </div>
                  {categories
                    ?.sort((cat1, cat2) => cat1.id - cat2.id)
                    ?.map((cat) => (
                      <div
                        key={"cat" + cat.id}
                        onClick={() => {
                          setSelectedProductCategory(cat.id);
                          setIsProductCatOpen(false);
                        }}
                        className={`category ${
                          selectedProductCategory === cat.id
                            ? "selected-category"
                            : ""
                        }`}
                      >
                        {cat.title}
                      </div>
                    ))}
                </div>
              </div>
            )}
            <div className="product-container">
              {categories_loading && all_products_loading ? (
                <div className="product-categories component-loading"></div>
              ) : (
                <div className="product-categories">
                  {categories
                    ?.sort((cat1, cat2) => cat1.id - cat2.id)
                    ?.map((cat) => (
                      <div
                        key={"cat" + cat.id}
                        className={`prod-category ${
                          selectedProductCategory === cat.id
                            ? "selected-category"
                            : ""
                        }`}
                        onClick={() => {
                          containerRef.current.scrollLeft = 0;
                          if (selectedProductCategory === cat.id) {
                            setSelectedProductCategory(-1);
                          } else {
                            setSelectedProductCategory(cat.id);
                            setIsProductCatOpen(false);
                          }
                        }}
                      >
                        <p>{cat.title}</p>
                        <img src={Arrow} alt="" />
                      </div>
                    ))}
                </div>
              )}
              <div className="products-container" ref={containerRef}>
                {categories_loading && all_products_loading ? (
                  <>
                    <div className="product component-loading"></div>
                    <div className="product component-loading"></div>
                    <div className="product component-loading"></div>
                    <div className="product component-loading"></div>
                  </>
                ) : (
                  products
                    ?.filter(
                      (prod) =>
                        prod.category_id === selectedProductCategory ||
                        selectedProductCategory === -1
                    )
                    .sort((prod1, prod2) => {
                      if (prod1.category_id !== prod2.category_id) {
                        return prod1.category_id - prod2.category_id;
                      } else {
                        return prod1.id - prod2.id;
                      }
                    })
                    .map((prod) => (
                      <div key={"prod" + prod.id} className="product">
                        <Link
                          to={`/${prod.title
                            .replace(/\s+/g, "-")
                            .toLowerCase()}`}
                        >
                          <img src={domainName + prod.product_img} alt="" />
                        </Link>
                        <div className="content">
                          <Link
                            to={`/${prod.title
                              .replace(/\s+/g, "-")
                              .toLowerCase()}`}
                            className="text"
                          >
                            <p className="title bold">{prod.title}</p>
                            <p className="about">{prod.description}</p>
                          </Link>
                          <Link
                            to={`/schedule-call?serviceId=${prod.category_id}&&productId=${prod.id}`}
                            className="button"
                          >
                            <button className="primary-btn">
                              Schedule call with us
                            </button>
                          </Link>
                        </div>
                      </div>
                    ))
                )}
              </div>
            </div>
          </div>
        </section>
        {/* ***************************************** Section 8 ***************************************** */}

        <section className="section-why-us">
          <div className="conatiner">
            <div className="heading">
              {" "}
              Why Choose{" "}
              <span>
                Aapka <span className="text-red">CFO?</span>
              </span>
            </div>
            <div className="detail-container">
              <div className="icon-wrapper">
                <Affordable />
                <span>
                  Affordable & <br /> Flexible Services
                </span>
              </div>
              <div className="icon-wrapper">
                <ExperiencedCFOs />
                <span>Experienced CFOs</span>
              </div>
              <div className="icon-wrapper">
                <TailoredSolutions />
                <span>Tailored Solutions</span>
              </div>
            </div>
          </div>
        </section>
        {/****************************************** Section 9 ******************************************/}
        {!home_content_loading && (
          <section className="section section-6">
            <div className="section-container section-6-container">
              <img src={Farmer} alt="" />
              <div className="text-container">
                <h1 className="section-heading">
                  {/* <span className='vertical-text'>Helping</span> */}
                  Supporting Farmers, <br />{" "}
                  <span className="bold" ref={section6Ref}>
                    <span className="text-red">₹1</span> at a Time{" "}
                  </span>
                </h1>
                <p className="text">
                  {homeContent?.farmer_description
                    .split("\n")
                    .map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                </p>
                <Link to="/schedule-call" className="call-btn">
                  <button className="primary-btn">
                    Learn More About Our Initiative
                  </button>
                  <span className="call-icon">
                    <Call />
                  </span>
                </Link>
              </div>
            </div>
          </section>
        )}

        {/****************************************** Section 8 ******************************************/}
        {!home_content_loading && homeContent?.reviews?.length > 0 ? (
          <section className=" section-8">
            <div className="section-container section-8-container">
              <h1 className="section-heading">
                What Our <span>Clients Say</span>{" "}
              </h1>

              <div className="client-reviews" ref={clientReviewRef}>
                {homeContent?.reviews?.map((review) => (
                  // <div className="review" key={"client_review" + review.id}>
                  //   <img src={domainName + "/uploads/" + review.image} alt="" />
                  //   <div className="text">
                  //     <p>{review.review}</p>
                  //     <div className="author">
                  //       <p className="bold">{review.reviewer_name}</p>
                  //       <p className="company-name">{review.company_name}</p>
                  //     </div>
                  //   </div>
                  // </div>
                  <div
                    className="testimonial-card review"
                    key={"client_review" + review.id}
                  >
                    {/* Top left quote icon */}
                    <div className="quote-icon top">
                      <Quote />
                    </div>

                    <div className="content">
                      {/* Testimonial Text */}
                      <p className="testimonial-text">{review.review}</p>

                      {/* Author Information */}
                      <p className="author-info">
                        - {review.reviewer_name}
                        <br />
                        <span className="author-company">
                          {review.company_name}
                        </span>
                      </p>
                    </div>

                    {/* Bottom right quote icon */}
                    <div className="quote-icon bottom">
                      <Quote />
                    </div>
                  </div>
                ))}
              </div>
              <div className="arrows">
                <span className="arrow primary-btn" onClick={scrollLeftReview}>
                  <ArrowBlack />
                </span>
                <span
                  className="arrow primary-btn reverse"
                  onClick={scrollRightReview}
                >
                  <ArrowBlack />
                </span>
              </div>
            </div>
          </section>
        ) : (
          <div ref={clientReviewRef}></div>
        )}
        {/****************************************** Section 7 ******************************************/}
        {/* <section className="section faq-section">
          <h2 className="faq-heading">
            Frequently Asked <span className="highlight">Questions</span>
          </h2>
          <Accordion>
            {FAQ.map((item, index) => (
              <Accordion.Item eventKey={index} key={index}>
                <Accordion.Header>{item.question}</Accordion.Header>
                <Accordion.Body>{item.answer}</Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </section> */}
        <FAQs />

        {/****************************************** Section 8 ******************************************/}
        {!home_content_loading && (
          <section className=" section-7">
            <div className="section-container section-7-container">
              <div className="connect-form">
                <h5 className="section-heading">Connect With Us</h5>
                <div className="contact-detail">
                  <div className="left">
                    <form>
                      <div className="input-grp">
                        <div className="input">
                          <label>First Name</label>
                          <input
                            type="text"
                            name="first_name"
                            placeholder="First Name"
                            {...register("first_name", {
                              required: true,
                              maxLength: 35,
                            })}
                          />
                          {errors.first_name?.type === "required" && (
                            <p className="form-error">
                              First name is required.
                            </p>
                          )}
                          {errors.first_name?.type === "maxLength" && (
                            <p className="form-error">
                              Length of first name can not be more than 35.
                            </p>
                          )}
                        </div>
                        <div className="input">
                          <label>Last Name</label>

                          <input
                            type="text"
                            name="last_name"
                            placeholder="Last Name"
                            {...register("last_name", {
                              required: true,
                              maxLength: 35,
                            })}
                          />
                          {errors.last_name?.type === "required" && (
                            <p className="form-error">Last name is required.</p>
                          )}
                          {errors.last_name?.type === "maxLength" && (
                            <p className="form-error">
                              Length of last name can not be more than 35.
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="input-grp">
                        <div className="input">
                          <label>Email</label>
                          <input
                            type="email"
                            name="email"
                            placeholder="Email"
                            {...register("email", {
                              required: true,
                            })}
                          />
                          {errors.email?.type === "required" && (
                            <p className="form-error">Email is required.</p>
                          )}
                        </div>
                        <div className="call-grp">
                          <label>Contact No.</label>

                          <div className="input">
                            <select
                              name=""
                              id=""
                              defaultValue={"+91"}
                              {...register("country_code")}
                            >
                              {CountryCodes.sort().map((code) => (
                                <option
                                  value={code.dial_code}
                                >{`${code.code} (${code.dial_code})`}</option>
                              ))}
                              {/* 
                                            <option value='+91'>+91</option>
                                            <option value='+92'>+92</option>
                                            <option value='+93'>+93</option>
                                            <option value='+94'>+94</option>
                                            <option value='+95'>+95</option> */}
                            </select>
                            <input
                              type="number"
                              name="phone_number"
                              placeholder="Phone Number"
                              {...register("phone_number", {
                                required: true,
                              })}
                            />
                          </div>
                          {errors.phone_number?.type === "required" && (
                            <p className="form-error">
                              Phone number is required.
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="input-grp">
                        <div className="input">
                          <label> Message</label>

                          <textarea
                            type="text"
                            placeholder="Message"
                            rows={3}
                            {...register("message", {
                              required: true,
                            })}
                          />
                          {errors.message?.type === "required" && (
                            <p className="form-error">
                              Message field can not be empty.
                            </p>
                          )}
                        </div>
                      </div>
                      <button onClick={handleSubmit(submitForm)}>Submit</button>
                    </form>
                  </div>
                  <div className="right">
                    <div className="item-wrapper">
                      <div className="item address-1">
                        <div className="icon">
                          {" "}
                          <Location width={20} height={20} />{" "}
                        </div>
                        <p>
                          112C, 138C Building No. 280, Taweelah, Abu Dhabi,
                          United Arab Emirates
                        </p>
                      </div>
                      <div className="item address-2">
                        <div className="icon">
                          {" "}
                          <Location width={20} height={20} />
                        </div>
                        <p>
                          501 Solitaire 1, Opp Infinity Mall, Link Road Malad
                          (West) Mumbai - 400064
                        </p>
                      </div>
                      <div className="item phone-number">
                        <div className="icon">
                          <Call width={20} height={20} />{" "}
                        </div>
                        <p>+91 93218 22974</p>
                      </div>
                      <div className="item email">
                        <div className="icon">
                          {" "}
                          <Email width={20} height={20} />{" "}
                        </div>
                        <p>info@aapkacfo.com</p>
                      </div>
                      <span className="item social">
                        <div className="logos">
                          {/* <span className='insta'>{<Insta />}</span> */}
                          <a
                            href="https://www.instagram.com/aapkacfo/"
                            className="instagram"
                            target="__blank"
                          >
                            {<Instagram width={16} height={16} />}
                          </a>
                          <a
                            href="https://www.facebook.com/profile.php?id=100062852317845"
                            className="facebook"
                            target="__blank"
                          >
                            {<Facebook width={16} height={16} />}
                          </a>
                          <a
                            href="https://www.linkedin.com/company/aapka-cfo/"
                            className="linkedin"
                            target="__blank"
                          >
                            {<LinkedIn width={16} height={16} />}
                          </a>
                          <a
                            href="https://twitter.com/AapkaCfo"
                            className="twitter"
                            target="__blank"
                          >
                            {<Twitter width={16} height={16} />}
                          </a>
                          {/* <a className='whatsapp'>{<WhatsApp />}</a> */}
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
        {/****************************************** Section 9 ******************************************/}
        {/* <section className="section section-9">
        <div className="section-container section-9-container">
          {home_content_loading ? (
            <div className="component-loading we-loading"></div>
          ) : (
            <p>We</p>
          )}
          {home_content_loading ? (
            <div className="component-loading we-text-loading"></div>
          ) : (
            <div className="text">
              <h1>truly believe in,</h1>
              <h1>
                <span className="bold">DHANDHA</span> AAP DEKHIYE
              </h1>
              <h1>
                BAKI SAB <span className="bold">HUM DEKHENGE</span>
              </h1>
            </div>
          )}
        </div>
      </section> */}
        <Footer />
      </div>
    </>
  );
};

export default Home;
