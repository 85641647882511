// Install react-helmet if not installed
// npm install react-helmet

import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
// import { metaTags } from "../metaTags";
import { useStateValue } from "../StateProvider";

const DynamicMetaTags = () => {
  const [{ metaTags }, dispatch] = useStateValue();
  const [currentMeta, setCurrentMeta] = useState();

  const location = useLocation();
  const hostname = window.location.hostname;
  // const currentMeta =
  //   Object.entries(metaTags).find(
  //     ([key]) => new URL(key).pathname === location.pathname
  //   )?.[1] || {};
  useEffect(() => {
    console.log()
    const tags = metaTags?.metaTags ? metaTags?.metaTags:{}
    setCurrentMeta(
      Object.entries(tags).find(
        ([key]) => new URL(key).pathname === location.pathname
      )?.[1] || {}
    );
    // console.log(metaTags)
  }, [metaTags,location]);
  return (
    <Helmet>
      <title>{currentMeta?.title || "Default Title"}</title>
      <meta
        name="description"
        content={currentMeta?.description || "Default Description"}
      />
       {/* Add keywords meta tag */}
       {currentMeta?.keywords && (
        <meta name="keywords" content={currentMeta.keywords} />
      )}
      {/* Add canonical URL meta tag */}
      {currentMeta?.canonical_url && (
        <link rel="canonical" href={currentMeta.canonical_url} />
      )}
      {metaTags?.sitemap && (
        // <link rel="canonical" href={currentMeta.canonical_url} />
        <link rel="sitemap" title="Sitemap"  type="application/xml" href={metaTags.sitemap} />
      )}
    </Helmet>
  );
};

export default DynamicMetaTags;
