import React, { useEffect, useState } from "react";
import ProductTable from "./Components/ProductTable";

import "./productsAndService.scss";
import { GetCategoryList } from "../../apiCall";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { CACHE_TIME } from "../../Constants";
import { Helmet } from "react-helmet";

const ProductsAndService = () => {
  const [categories, setCategories] = useState();
 

  useQuery(["get-categories"], () => GetCategoryList(), {
    // staleTime: STALE_TIME,
    cacheTime: CACHE_TIME,
    onSuccess: (data) => {
      // console.log(data);
      if (data?.data?.status[0].Error === "False") {
        setCategories(data.data.value);
      }
    },
    onError: (err) => {
      // console.log(err, "ERROR");
      if (err.message) {
        toast.error(err.message);
      } else {
        toast.error("Something went wrong");
      }
    },
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="products-and-services-main">
     
      <h1>Product And Services</h1>
      <p>Solutions for every challenge, opportunities for every goal.</p>
      <ProductTable products={categories} type={"products-service"} />
    </div>
  );
};

export default ProductsAndService;
