import React, { useEffect, useState } from "react";

import "./termsOfUse.scss";
import { GetTermsOfUse } from "../../apiCall";
import { useQuery } from "react-query";
import { CACHE_TIME } from "../../Constants";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

const TermsOfUse = () => {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [content, setContent] = useState();

  useQuery(["terms-of-use"], () => GetTermsOfUse(), {
    // staleTime: STALE_TIME,
    cacheTime: CACHE_TIME,
    onSuccess: (data) => {
      // console.log(data);
      if (data.data?.status[0].Error === "False") {
        setContent(data.data.value);
      }
    },
    onError: (err) => {
      // console.log(err, "ERROR");
      if (err.message) {
        toast.error(err.message);
      } else {
        toast.error("Something went wrong");
      }
    },
  });

  return (
    <div className="terms-of-use-main">
      
      {content && (
        <>
          <h1>Terms of use</h1>
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
        </>
      )}
    </div>
  );
};

export default TermsOfUse;
