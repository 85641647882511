import React from "react";
import "./FAQs.scss"
import { FAQ } from "../../../utils/data";
import { Accordion } from "react-bootstrap";

export default function FAQs() {
  return (
    <section className="section faq-section">
      <h2 className="faq-heading">
        Frequently Asked <span className="highlight">Questions</span>
      </h2>
      <Accordion>
        {FAQ.map((item, index) => (
          <Accordion.Item eventKey={index} key={index}>
            <Accordion.Header>{item.question}</Accordion.Header>
            <Accordion.Body>{item.answer}</Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </section>
  );
}
