import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import {
  GenerateRefreshToken,
  GetAllProducts,
  GetCategoryList,
  GetClientDetails,
  GetClientReviews,
  GetSessionQuestions,
  PostInquiryDetails,
  // PostInquiryMedia,
} from "../../apiCall";

import { ReactComponent as Email } from "../../Assets/icons/mail.svg";
import { ReactComponent as Call } from "../../Assets/icons/call-filled.svg";
import { ReactComponent as Location } from "../../Assets/icons/location.svg";
import { ReactComponent as Close } from "../../Assets/icons/close.svg";
import CountryCodes from "../../CountryCodes.json";
// import ClientImg from "../../Assets/home/client-img.svg";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay } from "swiper/modules";
import "./scheduleCall.scss";
import { toast } from "react-toastify";
import { CACHE_TIME, domainName } from "../../Constants";
// import { displayRazorpay } from "../../utils/Razorpay";
import { getUniqueQuestions } from "../../utils/utils";
import { useStateValue } from "../../StateProvider";
import { Helmet } from "react-helmet";
// import {premium_price} from "../../Constants"

// const clientReview = [
//     {
//         img: ClientImg,
//         name: "Shailesh",
//         review: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit",
//         company: "shaileshtech company",
//     },
//     {
//         img: ClientImg,
//         name: "Shailesh",
//         review: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit",
//         company: "shaileshtech company",
//     },
//     {
//         img: ClientImg,
//         name: "Shailesh",
//         review: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit",
//         company: "shaileshtech company",
//     },
// ];

const ScheduleCall = () => {
  let slidesPerView = 6.5;
  if (window.innerWidth <= 1150) {
    slidesPerView = 4.5;
  } else if (window.innerWidth <= 1450) {
    slidesPerView = 5.5;
  }

  const navigateTo = useNavigate();
  const [searchParams] = useSearchParams();
  // const fileInputRef = useRef();
  // const clientReviewRef = useRef();

  // const [selectedService, setSelectedService] = useState(undefined);
  const [selectedService, setSelectedService] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [sessionQuestions, setSessionQuestions] = useState([]);
  const [uniqueQuestions, setUniqueQuestions] = useState([]);
  const [sessionAnswers, setSessionAnswers] = useState({});
  // const [sessionAnswersErrors, setSessionAnswersErrors] = useState({});
  const [categories, setCategories] = useState();
  const [products, setProducts] = useState();
  const [productError, setProductError] = useState("");
  // const [file, setFile] = useState();
  const [clientDetails, setClientDetails] = useState();
  const [clientReviews, setClientReviews] = useState();
  const [, dispatch] = useStateValue();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ defaultValues: { country_code: "+91" } });

  // const validateSessionAnswers = () => {
  //     let hasErrors = false;
  //     for (let j = 0; j < uniqueQuestions.length; j++) {
  //         const question = uniqueQuestions[j];
  //         const key = question.id;
  //         const answer = sessionAnswers[key];
  //         // console.log(
  //             question.id,
  //             answer,
  //             question.dependent_question,
  //             question.dependent_answer,
  //             sessionAnswers[question.dependent_question]
  //         );
  //         if (
  //             !question.dependent_question ||
  //             sessionAnswers[question.dependent_question] ===
  //                 question.dependent_answer
  //         ) {
  //             if (typeof answer === "object") {
  //                 if (answer.length) {
  //                     setSessionAnswersErrors((pre) => {
  //                         const new_errors = { ...pre };
  //                         new_errors[key] = false;
  //                         return new_errors;
  //                     });
  //                 } else {
  //                     // console.log(key);
  //                     setSessionAnswersErrors((pre) => {
  //                         const new_errors = { ...pre };
  //                         new_errors[key] = true;
  //                         return new_errors;
  //                     });
  //                     hasErrors = true;
  //                 }
  //             } else {
  //                 if (answer) {
  //                     setSessionAnswersErrors((pre) => {
  //                         const new_errors = { ...pre };
  //                         new_errors[key] = false;
  //                         return new_errors;
  //                     });
  //                 } else {
  //                     // console.log(key);
  //                     setSessionAnswersErrors((pre) => {
  //                         const new_errors = { ...pre };
  //                         new_errors[key] = true;
  //                         return new_errors;
  //                     });
  //                     hasErrors = true;
  //                 }
  //             }
  //         } else {
  //             setSessionAnswersErrors((pre) => {
  //                 const new_errors = { ...pre };
  //                 new_errors[key] = true;
  //                 return new_errors;
  //             });
  //         }
  //     }
  //     return hasErrors;
  // };
  const scheduleACall = async (data) => {
    if (!selectedProducts?.length) {
      setProductError("Please select any product.");
      return;
    } else {
      setProductError("");
    }
    data.category = selectedService;
    data.products = selectedProducts;

    // const hasErrors = validateSessionAnswers();
    // // console.log(hasErrors);
    // // console.log(sessionAnswersErrors);
    // if (hasErrors) {
    //     return;
    // }

    const session_answers = sessionQuestions?.map((product) => {
      const product_obj = {
        product_id: product.id,
        product_title: product.title,
        answers: product.session_questions.map((question) => {
          if (Array.isArray(sessionAnswers[question.id])) {
            const q_a_obj = {
              question: question.question,
              answer: sessionAnswers[question.id]?.includes("Other")
                ? [
                    ...sessionAnswers[question.id],
                    sessionAnswers[question.id + "other"],
                  ]
                : sessionAnswers[question.id],
            };
            return q_a_obj;
          } else {
            const q_a_obj = {
              question: question.question,
              answer:
                sessionAnswers[question.id] === "Other"
                  ? sessionAnswers[question.id + "other"]
                  : sessionAnswers[question.id],
            };
            return q_a_obj;
          }
        }),
      };
      return product_obj;
    });
    data.question_answers = session_answers;
    // console.log(data);

    const reDetails = await PostInquiryDetails(data);
    // console.log(reDetails, "RE DETAILS");

    // if (
    //     reDetails?.data?.status[0].Error === "False" &&
    //     reDetails.data.status[0].Message === "need_premium"
    // ) {
    //     displayRazorpay(premium_price, true);
    // } else
    if (reDetails?.data?.status[0].Error === "False") {
      // if (file) {
      //     const fileData = new FormData();
      //     fileData.append("media", file);
      //     const re = await PostInquiryMedia(
      //         reDetails.data.value,
      //         fileData
      //     );
      //     // console.log(re);
      //     const reMessage = re.data?.status[0].ResponseMessage;
      //     if (re.data?.status[0].Error === "False") {
      //         toast.success("Inquiry submitted.");
      //         reset();
      //         setFile();
      //         setSelectedProducts();
      //         setSelectedService([]);
      //         setSessionAnswers({});
      //     } else {
      //         toast.error(reMessage);
      //     }
      // } else {
      toast.success("Inquiry submitted.");
      navigateTo("/thank-you");
      reset();
      // setFile();
      setSelectedProducts([]);
      setSelectedService([]);
      setSessionAnswers({});
      // }
    } else {
      if (reDetails?.data?.status[0].Error === "True") {
        toast.error(reDetails.data.status[0].ResponseMessage);
      } else {
        if (
          reDetails?.response?.data?.detail ===
          "Unauthenticated User, Please login first."
        ) {
          const refreshRe = await GenerateRefreshToken();

          console.log(refreshRe, "REFRESH RE");

          if (refreshRe?.data?.access_token) {
            localStorage.setItem(
              "refresh_token",
              refreshRe?.data?.refresh_token
            );
            localStorage.setItem("token", refreshRe?.data?.access_token);
            scheduleACall(data);
          } else {
            localStorage.clear();
            dispatch({
              type: "SET_USER_LOGGED_IN",
              status: false,
            });
          }
        } else {
          toast.error("Something went wrong, Please try agian.");
        }
      }
    }
  };

  const toggleSelection = (e, id) => {
    e.preventDefault();
    if (selectedProducts?.includes(id)) {
      setSelectedProducts((pre) => pre.filter((prodId) => prodId !== id));
    } else {
      setSelectedProducts((pre) => [...pre, id]);
    }
  };

  const handleServiceSelect = (e, id) => {
    e.preventDefault();
    if (selectedService?.includes(id)) {
      setSelectedService((pre) => pre.filter((serviceId) => serviceId !== id));
    } else {
      setSelectedService((pre) => [...pre, id]);
    }
  };

  useQuery(["get-categories"], () => GetCategoryList(), {
    // staleTime: STALE_TIME,
    cacheTime: CACHE_TIME,
    onSuccess: (data) => {
      // // console.log(data);
      if (data.data?.status[0].Error === "False") {
        setCategories(data?.data.value);
      }
    },
    onError: (err) => {
      // console.log(err, "ERROR");
      if (err.message) {
        toast.error(err.message);
      } else {
        toast.error("Something went wrong");
      }
    },
  });
  useQuery(["products-all-products"], () => GetAllProducts(), {
    // staleTime: STALE_TIME,
    cacheTime: CACHE_TIME,
    onSuccess: (data) => {
      // // console.log(data);
      if (data.data?.status[0].Error === "False") {
        setProducts(data.data.value);
      }
    },
    onError: (err) => {
      // console.log(err, "ERROR");
      if (err.message) {
        toast.error(err.message);
      } else {
        toast.error("Something went wrong");
      }
    },
  });
  useQuery(
    ["session-questions", selectedProducts],
    () => GetSessionQuestions(selectedProducts),
    {
      enabled: selectedProducts ? true : false,
      // staleTime: STALE_TIME,
      cacheTime: CACHE_TIME,
      onSuccess: (data) => {
        // console.log(data);
        if (data.data?.status[0].Error === "False") {
          setSessionQuestions(data.data.value);
          setUniqueQuestions(getUniqueQuestions(data.data.value));
        }
      },
      onError: (err) => {
        // console.log(err, "ERROR");
        if (err.message) {
          toast.error(err.message);
        } else {
          toast.error("Something went wrong");
        }
      },
    }
  );

  useQuery(["get-client-review"], () => GetClientReviews(), {
    // staleTime: STALE_TIME,
    cacheTime: CACHE_TIME,
    onSuccess: (data) => {
      // // console.log(data);
      if (data?.data?.status[0].Error === "False") {
        setClientReviews(data.data.value);
      }
    },
    onError: (err) => {
      // console.log(err, "ERROR");
      if (err.message) {
        toast.error(err.message);
      } else {
        toast.error("Something went wrong");
      }
    },
  });
  useQuery(["get-client"], () => GetClientDetails(), {
    // staleTime: STALE_TIME,
    cacheTime: CACHE_TIME,
    onSuccess: (data) => {
      // // console.log(data);
      if (data?.data?.status[0].Error === "False") {
        setClientDetails(data.data.value);
      }
    },
    onError: (err) => {
      // console.log(err, "ERROR");
      if (err.message) {
        toast.error(err.message);
      } else {
        toast.error("Something went wrong");
      }
    },
  });
  useEffect(() => {
    if (searchParams.get("serviceId")) {
      setSelectedService([parseInt(searchParams.get("serviceId"))]);
    }
    if (searchParams.get("productId")) {
      setSelectedProducts((pre) => [
        ...pre,
        parseInt(searchParams.get("productId")),
      ]);
    }
    window.scrollTo(0, 0);
  }, [searchParams]);
  return (
    <div className="schedule-call-main">
      <Link
        to={
          searchParams.get("productId")
            ? `/product-details/${searchParams.get("productId")}`
            : "/home"
        }
        className="primary-btn close"
      >
        <Close />
      </Link>
      <div className="section-1">
        <h1>Welcome!</h1>
        <h3 className="bold">
          We're glad you're here and we are ready to assist you.
        </h3>
        <p className="description">
          Let's connect—schedule a call with our team at your convenience, and
          we'll guide you every step of the way. Currently, we don’t have access
          to edit this page, so we’d appreciate your assistance.
        </p>

        <form onSubmit={handleSubmit(scheduleACall)}>
          <div className="input-grp">
            <label htmlFor="name">What is your name?</label>
            <input
              type="text"
              name="name"
              placeholder="Name"
              {...register("name", {
                required: true,
                maxLength: 70,
              })}
            />
            {errors.name?.type === "required" && (
              <p className="form-error">Name is required.</p>
            )}
            {errors.name?.type === "maxLength" && (
              <p className="form-error">
                Length of name can not be more than 70.
              </p>
            )}
          </div>
          <div className="input-grp">
            <label htmlFor="email">What is your email?</label>
            <input
              type="email"
              name="email"
              placeholder="Email"
              {...register("email", {
                required: true,
              })}
            />
            {errors.email?.type === "required" && (
              <p className="form-error">Email is required.</p>
            )}
          </div>
          <div className="input-grp">
            <label htmlFor="phone">What is your phone number?</label>
            <div className="call-grp">
              <select name="country" {...register("country_code")}>
                {CountryCodes.sort().map((code) => (
                  <option
                    value={code.dial_code}
                  >{`${code.code} (${code.dial_code})`}</option>
                ))}

                {/* <option value='+1'>UK</option>
                                <option value='+2'>Us</option> */}
              </select>
              <input
                type="number"
                name="phone"
                placeholder="Phone Number"
                {...register("phone_number", {
                  required: true,
                })}
              />
            </div>
            {errors.phone_number?.type === "required" && (
              <p className="form-error">Phone number is required.</p>
            )}
          </div>
          {/* <div className='company-details'>
                        <div className='input-grp'>
                            <label htmlFor='company-name'>
                                What is your company name?
                            </label>
                            <input
                                type='text'
                                name='company-name'
                                placeholder='Company Name'
                                {...register("company_name", {
                                    required: true,
                                    maxLength: 70,
                                })}
                            />
                            {errors.company_name?.type === "required" && (
                                <p className='form-error'>
                                    company name is required.
                                </p>
                            )}
                            {errors.company_name?.type === "maxLength" && (
                                <p className='form-error'>
                                    Length of company name can not be more than
                                    70.
                                </p>
                            )}
                        </div>
                        <div className='input-grp'>
                            <label htmlFor='sector'>
                                In which sector/field do you operate?
                            </label>
                            <input
                                type='text'
                                name='sector'
                                placeholder='E-Commerce, Education, etc...'
                                {...register("sector", {
                                    required: true,
                                    maxLength: 70,
                                })}
                            />
                            {errors.sector?.type === "required" && (
                                <p className='form-error'>Name is required.</p>
                            )}
                            {errors.sector?.type === "maxLength" && (
                                <p className='form-error'>
                                    Length of sector can not be more than 70.
                                </p>
                            )}
                        </div>
                    </div> */}
          {/* <div className='company-details'>
                        <div className='input-grp'>
                            <label htmlFor='how_did_you_hear'>
                                How did you hear about us?
                            </label>
                            <input
                                type='text'
                                name='how_did_you_hear'
                                placeholder='LinkedIn, Offline Networking, etc...'
                                {...register("how_did_you_hear", {
                                    required: true,
                                    maxLength: 70,
                                })}
                            />
                            {errors.how_did_you_hear?.type === "required" && (
                                <p className='form-error'>
                                    This question is required.
                                </p>
                            )}
                            {errors.how_did_you_hear?.type === "maxLength" && (
                                <p className='form-error'>
                                    Length of this question can not be more than
                                    70.
                                </p>
                            )}
                        </div>
                        <div className='input-grp'>
                            <label htmlFor='position'>
                                Write your title/position
                            </label>
                            <input
                                type='text'
                                name='position'
                                placeholder='Position'
                                {...register("position", {
                                    required: true,
                                    maxLength: 70,
                                })}
                            />
                            {errors.position?.type === "required" && (
                                <p className='form-error'>Name is required.</p>
                            )}
                            {errors.position?.type === "maxLength" && (
                                <p className='form-error'>
                                    Length of position can not be more than 70.
                                </p>
                            )}
                        </div>
                    </div> */}
          <div className="input-grp">
            {/* {selectedService ? (
                            <span
                                className='arrow primary-btn'
                                onClick={() => {
                                    setSelectedProducts([]);
                                    setSelectedService([]);
                                }}>
                                <ArrowBlack />
                            </span>
                        ) : (
                            <></>
                        )} */}
            {/* {selectedService ? (
                            products?.length > 0 && (
                                <p className='bold'>
                                    Service :{" "}
                                    {
                                        categories?.filter(
                                            (cat) => cat.id === selectedService
                                        )[0].title
                                    }
                                </p>
                            )
                        ) : (
                            <p>Select your service</p>
                        )} */}
            {/* {selectedService
                            ? products?.length > 0 && (
                                <div className='choices'>
                                    {products.map((product) => (
                                        <button
                                            key={"product" + product.id}
                                            className={`primary-btn ${selectedProducts.includes(
                                                product.id
                                            )
                                                ? "selected"
                                                : ""
                                                }`}
                                            onClick={(e) =>
                                                toggleSelection(e, product.id)
                                            }>
                                            {product.title}
                                        </button>
                                    ))}
                                </div>
                            )
                            : categories?.length > 0 && (
                                <div className='choices'>
                                    {categories.map((service) => (
                                        <button
                                            key={"service" + service.id}
                                            className='primary-btn'
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setSelectedService(pre => [...pre, service.id]);
                                            }}>
                                            {service.title}
                                        </button>
                                    ))}
                                </div>
                            )} */}

            <label>Select your service</label>
            {categories?.length > 0 && (
              <div className="choices">
                {categories.map((service) => (
                  <button
                    key={"service" + service.id}
                    className={`primary-btn ${
                      selectedService?.includes(service.id) ? "selected" : ""
                    }`}
                    onClick={(e) => handleServiceSelect(e, service.id)}
                  >
                    {service.title}
                  </button>
                ))}
              </div>
            )}
          </div>
          {selectedService?.length > 0 && (
            <div className="input-grp">
              <label>Select your product</label>
              <div className="choices">
                {products
                  ?.filter((prod) =>
                    selectedService?.includes(prod.category_id)
                  )
                  ?.map((product) => (
                    <button
                      key={"product" + product.id}
                      className={`primary-btn ${
                        selectedProducts?.includes(product.id) ? "selected" : ""
                      }`}
                      onClick={(e) => toggleSelection(e, product.id)}
                    >
                      {product.title}
                    </button>
                  ))}
              </div>
              <p className="form-error">{productError}</p>
            </div>
          )}
          <div className="session-questions-container">
            {uniqueQuestions
              ?.sort((q1, q2) => q1.id - q2.id)
              ?.map(
                (question) =>
                  (!question.dependent_question ||
                    sessionAnswers[question.dependent_question] ===
                      question.dependent_answer) && (
                    <div
                      className="input-grp session-questions"
                      key={"question" + question.id}
                    >
                      <label htmlFor="notes">{question.question}</label>
                      {question.input_type === "MultiSelect" && (
                        <p className="information">
                          Hold down the Ctrl (windows) or Command (Mac) button
                          to select multiple options.
                        </p>
                      )}
                      {question.input_type === "Text" && (
                        <input
                          type="text"
                          name="note"
                          placeholder="Type here..."
                          value={
                            sessionAnswers[question.id]
                              ? sessionAnswers[question.id]
                              : ""
                          }
                          onChange={(e) =>
                            setSessionAnswers((pre) => {
                              const new_answers = {
                                ...pre,
                              };
                              new_answers[question.id] = e.target.value;
                              return new_answers;
                            })
                          }
                        />
                      )}
                      {question.input_type === "Dropdown" && (
                        <>
                          <select
                            value={
                              sessionAnswers[question.id]
                                ? sessionAnswers[question.id]
                                : ""
                            }
                            onChange={(e) =>
                              setSessionAnswers((pre) => {
                                const new_answers = {
                                  ...pre,
                                };
                                new_answers[question.id] = e.target.value;
                                return new_answers;
                              })
                            }
                          >
                            <option value="">Select Answer</option>
                            {question.options.map((option) => (
                              <option
                                key={`option-${question.id}-${option.id}`}
                                value={option.value}
                              >
                                {option.value}
                              </option>
                            ))}
                          </select>
                          {sessionAnswers[question.id] === "Other" && (
                            <input
                              type="text"
                              name="other"
                              placeholder="Type here for above question..."
                              value={
                                sessionAnswers[question.id + "other"]
                                  ? sessionAnswers[question.id + "other"]
                                  : ""
                              }
                              onChange={(e) =>
                                setSessionAnswers((pre) => {
                                  const new_answers = {
                                    ...pre,
                                  };
                                  new_answers[question.id + "other"] =
                                    e.target.value;
                                  return new_answers;
                                })
                              }
                            />
                          )}
                        </>
                      )}
                      {question.input_type === "MultiSelect" && (
                        <>
                          <select
                            value={
                              sessionAnswers[question.id]
                                ? sessionAnswers[question.id]
                                : []
                            }
                            onChange={(e) =>
                              setSessionAnswers((pre) => {
                                const new_answers = {
                                  ...pre,
                                };
                                new_answers[question.id] = Array.from(
                                  e.target.selectedOptions,
                                  (option) => option.value
                                );
                                return new_answers;
                              })
                            }
                            multiple
                          >
                            {question.options.map((option) => (
                              <option
                                key={`multi-option-${question.id}-${option.id}`}
                                value={option.value}
                              >
                                {option.value}
                              </option>
                            ))}
                          </select>
                          {sessionAnswers[question.id]?.includes("Other") && (
                            <input
                              type="text"
                              name="other"
                              placeholder="Type here for above question..."
                              value={
                                sessionAnswers[question.id + "other"]
                                  ? sessionAnswers[question.id + "other"]
                                  : ""
                              }
                              onChange={(e) =>
                                setSessionAnswers((pre) => {
                                  const new_answers = {
                                    ...pre,
                                  };
                                  new_answers[question.id + "other"] =
                                    e.target.value;
                                  return new_answers;
                                })
                              }
                            />
                          )}
                        </>
                      )}
                      {/* {sessionAnswersErrors[question.id] && (
                                            <p className='form-error'>
                                                This field is required.
                                            </p>
                                        )} */}
                    </div>
                  )
              )}
          </div>
          {/* <div className='file-upload'>
                        <p className='title'>Upload</p>
                        <p className='description'>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Sed ac ligula e.
                        </p>
                        <input
                            type='file'
                            hidden
                            ref={fileInputRef}
                            onChange={(e) => setFile(e.target.files[0])}
                        />
                        <div
                            className='file-upload-div'
                            onClick={() => fileInputRef.current.click()}>
                            Click to browse or drag and drop your files
                        </div>
                    </div> */}
          {/* <div className='input-grp'>
                        <label htmlFor='timezone'>Time Zone</label>
                        <select name='timezone' {...register("time_zone")}>
                            <option value='(GMT + 05:30) Asia/Kolkata'>
                                (GMT + 05:30) Asia/Kolkata
                            </option>
                            <option value='(GMT + 05:30) Asia/Kolkata'>
                                (GMT + 05:30) Asia/Kolkata
                            </option>
                            <option value='(GMT + 05:30) Asia/Kolkata'>
                                (GMT + 05:30) Asia/Kolkata
                            </option>
                            <option value='(GMT + 05:30) Asia/Kolkata'>
                                (GMT + 05:30) Asia/Kolkata
                            </option>
                        </select>
                    </div> */}
          <div className="input-grp">
            <label htmlFor="notes">Additional Notes</label>
            <input
              type="text"
              name="note"
              placeholder="Any additional information or specific requirements for call"
              {...register("note")}
            />
          </div>
          <button type="submit" className="primary-btn submit-btn">
            Submit
          </button>
        </form>
      </div>
      <div className="section-2">
        <div className="contact-container">
          <div className="detail">
            <span>
              <Email />
              <p>Email</p>
            </span>
            <a href="mailto:info@aapkacfo.com" target="__blank">
              info@aapkacfo.com
            </a>
          </div>
          <div className="detail">
            <span>
              <Call />
              <p>Phone</p>
            </span>
            <a href="tel:+919321822974" target="__blank">
              +91 9321 822 974
            </a>
          </div>
          <div className="address-details">
            <div className="detail">
              <span>
                <Location />
                <p>Out Offices</p>
              </span>
            </div>
            <div className="address">
              <h5>India</h5>
              <p>
                501 Solitaire 1, Opp Infinity Mall, Link Road Malad (West)
                Mumbai - 400064
              </p>
            </div>
            <div className="address">
              <h5>UAE</h5>
              <p>
                112C, 138C Building No. 280, Taweelah, Abu Dhabi, United Arab
                Emirates
              </p>
            </div>
          </div>
        </div>
        {/* {clientReviews?.length > 0 && (
                    <div className='client-review'>
                        <h3 className='section-heading'>
                            What Our <span className='bold'>Client Say's </span>
                        </h3>
                        <Swiper
                            spaceBetween={10}
                            centeredSlides={true}
                            autoplay={{
                                delay: 5000,
                                disableOnInteraction: false,
                            }}
                            loop={true}
                            modules={[Autoplay]}
                            className='mySwiper'
                            slidesPerView={1}
                            speed={300}>
                            {clientReviews?.map((review, indx) => (
                                <SwiperSlide key={"client-review" + indx}>
                                    <div className='review'>
                                        <img
                                            src={
                                                domainName +
                                                "/uploads/" +
                                                review.image
                                            }
                                            alt=''
                                        />
                                        <div className='text'>
                                            <p>{review.review}</p>
                                            <div className='author'>
                                                <p className='bold'>
                                                    {review.reviewer_name}
                                                </p>
                                                <p className='company-name'>
                                                    {review.company_name}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                )} */}
        {/* {clientDetails && (
                    <div className='clients-companies'>
                        <h3 className='section-heading'>
                            Clients & companies{" "}
                            <span className='bold'>we work with</span>{" "}
                        </h3>
                        <div className='client-logo-swiper'>
                            <Swiper
                                spaceBetween={24}
                                centeredSlides={true}
                                loopedSlides={true}
                                autoplay={{
                                    delay: 500,
                                    disableOnInteraction: false,
                                }}
                                loop={true}
                                modules={[Autoplay]}
                                className='mySwiper'
                                slidesPerView={slidesPerView}
                                // slidesPerGroup={3}
                                speed={500}>
                                {clientDetails?.map((client) => (
                                    <SwiperSlide key={"client" + client.id}>
                                        <div className='client'>
                                            <img
                                                src={
                                                    domainName +
                                                    "/uploads/" +
                                                    client.company_logo
                                                }
                                                alt=''
                                            />
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </div>
                )} */}
      </div>
    </div>
  );
};

export default ScheduleCall;
